import './App.css';
import posts from './fake-posts';
import image from './kishore.jpg';
import github_icon from './GitHub-Mark.png';
import { useEffect } from 'react';

const intro = <div><p>I am a computer programmer and science educator
with over a decade of experience in multidisciplinary roles. I enjoyed
working for several years in a school, teaching science and programming
to students of grades 6-10.</p> <p>More recently I worked in the educational
technology industry, in roles ranging from academic content, data analysis, and
development of educational web applications.</p><p> Currently I am interested in working
more directly on innovative software solutions as a developer.</p></div>;

const desc = (<div>
  <p>I love playing around with ideas and working on hobby programming projects. You
  can see some of them below:</p>
</div>);

const list = (<div>
  <ul style={{float: 'left'}}>
    <li>
      <a href="https://akishore.in/lispy-js" target="_blank">
        Lispy-JS: Scheme Interpreter in JavaScript
      </a>
      <a href="https://github.com/athkishore/lispy-js-core" target="_blank">
        <img src={github_icon} alt="" width={20 + 'px'}/>
      </a>
    </li>
    <li>
      <a href="https://forgottenscience.org" target="_blank">
        Forgotten Science: A blog on the history of science
      </a>
      <a href="https://github.com/athkishore/fgsci" target="_blank">
        <img src={github_icon} alt="" width={20 + 'px'}/>
      </a>
    </li>
  </ul>
</div>);

function App() {
  useEffect(() => {
    document.title = `Kishore's Home Page`;
  });

  return (
    <div className="App">
      <div className="topnav">
        <a className="active" href="#home">Home</a>
      </div>

      <div id="container">

        <h2>Kishore's Home Page</h2>
        <img src={image} align={'left'} width={150+'px'} alt=""/>
        {intro}
        {desc}
        {list}
      </div>


    </div>
  );
}
console.log(posts[0].title);
export default App;
