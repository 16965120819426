const posts = [
  {
    id: 1,
    title: 'Post 1',
    contents: 'Hello world'
  },
  {
    id: 2,
    title: 'Post 2',
    contents: 'Test post'
  },
  {
    id: 3,
    title: 'Post 3',
    contents: 'Another test post'
  },
];

export default posts;
